import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Cta } from '../components';
import {
  color, desktopVW, mobileVW, minTablet,
} from '../styles';
import {
  Card, DateSelector, PersonSelector, PackageSelector, UpgradeSelectors, ShippingSelector,
} from '../components/configurator';
import { dayInMs, getUniqueListBy } from '../utils';
import { OrderForm } from '../components/OrderForm';
import { Hero } from '../components/hero/Hero';

type PageType = {
  wpProductConfiguratorPage : {
    acfHeader: {
      hero: any
    }
    configurator: {
      mainProduct: string,
      cta: {
        title: string,
        column1: string,
        column2: string,
        button: {
          url: string,
          title: string
        }
      }
      options: [],
      info: string
    }
  }
  allShopifyProduct: {
    edges: []
  }
}

const Configurator = ({ data }: {data: PageType}) => {
  const { hero } = data.wpProductConfiguratorPage.acfHeader;
  const {
    cta, options, info,
  } = data.wpProductConfiguratorPage.configurator;

  const date = new Date();
  const miniumDays = new Date(date.getTime() + (Number(dayInMs) * 3));
  const [selectedDate, setSelectedDate] = useState([date, miniumDays]);
  const [totalDays, setTotalDays] = useState(0);
  const [quantity, setQuantity] = useState(5);
  const [products, setProducts] = useState([]);
  const [returnShipping, setReturnShipping] = useState('');

  const mainProduct = data.allShopifyProduct.edges.filter((item : any) => data.wpProductConfiguratorPage.configurator.mainProduct === item.node.handle);

  useEffect(() => {
    if (selectedDate[0] && selectedDate[1]) {
      // @ts-ignore hele rare error
      setTotalDays(Math.abs(Math.round(selectedDate[0].getTime() - selectedDate[1].getTime()) / Number(dayInMs)).toFixed(0));
    }
  }, [selectedDate]);

  useEffect(() => {
    const minimalRentalDays = 3;
    if (totalDays < minimalRentalDays) setSelectedDate([selectedDate[0], new Date(selectedDate[0].getTime() + (Number(dayInMs) * minimalRentalDays))]);
  }, [totalDays]);

  const handleQuantity = (value : string) => {
    setQuantity(parseInt(value, 10) > 0 ? parseInt(value, 10) : 0);
  };

  useEffect(() => {
    // @ts-ignore
    setProducts(getUniqueListBy([...products, { ...mainProduct[0].node, quantity }], 'title'));
  }, [totalDays, quantity]);

  return (
    <>
      <Hero hero={hero} />
      <Cta title={cta.title} column1={cta.column1} column2={cta.column2} button={cta.button} />
      <Background id="start">
        <StyledInfo>{info}</StyledInfo>
        <StyledContainer>
          <StyledColumn>
            {options.map((item : any) => {
              switch (item.fieldGroupName) {
                case 'ProductConfiguratorPage_Configurator_Options_DateSelector':
                  return (
                    <Card key={item.title} secondaryTitle={totalDays && `${totalDays} dagen`} description={item.description} title={item.title}>
                      <DateSelector value={selectedDate} setValue={setSelectedDate} data={item} />
                    </Card>
                  );
                case 'ProductConfiguratorPage_Configurator_Options_PersonSelector':
                  return (
                    <Card key={item.title} description={item.description} title={item.title}>
                      <PersonSelector value={quantity} handleQuantity={handleQuantity} data={item} />
                    </Card>
                  );
                case 'ProductConfiguratorPage_Configurator_Options_PackageSelector':
                  return (
                    <Card key={item.title} description={item.description} title={item.title}>
                      <PackageSelector getProducts={products} setProducts={setProducts} product={data.allShopifyProduct.edges.filter((shopify : { node : { handle : string }}) => item.shopifyProduct === shopify.node.handle)[0]} totalDays={totalDays} quantity={quantity} data={item} />
                    </Card>
                  );
                case 'ProductConfiguratorPage_Configurator_Options_UpgradeSelectors':
                  return (
                    <Card key={item.title} description={item.description} title={item.title}>
                      <UpgradeSelectors totalDays={totalDays} getProducts={products} setProducts={setProducts} data={item} products={data.allShopifyProduct.edges} />
                    </Card>
                  );
                case 'ProductConfiguratorPage_Configurator_Options_ShippingSelector':
                  return (
                    <Card key={item.title} description={item.description} title={item.title}>
                      <ShippingSelector setReturnShipping={setReturnShipping} data={item} />
                    </Card>
                  );
                default:
                  return null;
              }
            })}
          </StyledColumn>
          <StyledColumn>
            <Sticky>
              <StyledHelp>
                <p>Hulp nodig? Bel ons:</p>
                <p>
                  <u>040 - 9224 41241</u>
                  {' '}
                  (08:00 — 18:00)
                  {' '}
                </p>
              </StyledHelp>
              <OrderForm returnShipping={returnShipping} quantity={quantity} mainProduct={data.wpProductConfiguratorPage.configurator.mainProduct} totalDays={totalDays} setProducts={setProducts} products={products} selectedDate={selectedDate} />
            </Sticky>
          </StyledColumn>
        </StyledContainer>
      </Background>
    </>
  );
};

const Background = styled.div`
  background: ${color.greyLight};
  padding: ${mobileVW(60)} 0;

  ${minTablet} {
    padding: ${desktopVW(60)} 0;
  }
`;

const StyledInfo = styled.p`
  text-align: center;
  padding: 0 ${mobileVW(30)};

  ${minTablet} {
    padding: 0 ${desktopVW(60)};
  }
`;

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${mobileVW(30)};
  margin: ${mobileVW(60)} 0 0 0;

  ${minTablet} {
    grid-template-columns: ${desktopVW(900)} auto;
    grid-gap: 0 ${desktopVW(30)};
    margin: ${desktopVW(60)};
  }
`;

const StyledColumn = styled.div`
  position: sticky;
`;

const Sticky = styled.div`
  position: sticky;
  top: ${desktopVW(30)};
`;

const StyledHelp = styled.div`
  background: ${color.black};
  color: ${color.white};
  margin-bottom: ${desktopVW(30)};
  padding: ${desktopVW(30)};
  border-radius: ${desktopVW(10)};

  display: none;

  ${minTablet} {
    display: block;
  }
`;

export const query = graphql`
  query($products: [String]!, $handle: String!) {
    wpProductConfiguratorPage(slug: { eq: $handle }) {
      id
      configurator {
        info
        mainProduct
        options {
          ... on WpProductConfiguratorPage_Configurator_Options_DateSelector {
            fieldGroupName
            description
            returnDate
            startDate
            title
          }
          ... on WpProductConfiguratorPage_Configurator_Options_PersonSelector {
            fieldGroupName
            description
            inputField
            title
          }
          ... on WpProductConfiguratorPage_Configurator_Options_PackageSelector {
            fieldGroupName
            description
            title
            shopifyProduct
            packages {
              transmittors
            }
          }
          ... on WpProductConfiguratorPage_Configurator_Options_UpgradeSelectors {
            fieldGroupName
            products {
              shopifyProduct
              info
            }
            title
          }
          ... on WpProductConfiguratorPage_Configurator_Options_ShippingSelector {
            fieldGroupName
            description
            title
            delivery {
              title
              couriers {
                courier
              }
            }
            return {
              couriers {
                courier
              }
              title
            }
          }
        }
        cta {
          title
          column2
          column1
          button {
            title
            url
          }
        }
      }
      acfHeader {
        hero {
          layout
          heading
        }
      }
    }
    allShopifyProduct(filter: {handle: {in: $products}}) {
      edges {
        node {
          id
          handle
          storefrontId
          title
          shopifyId
          variants {
            title
            price
            storefrontId
          }
          priceRangeV2 {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }
`;

export default Configurator;
