/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAddItemsToCart } from '../hooks/shop/useAddItemsToCart';
import { useCart } from '../hooks/shop/useCart';
import { useCheckoutUrl } from '../hooks/shop/useCheckoutUrl';
import { useRemoveItemsFromCart } from '../hooks/shop/useRemoveItemsFromCart';
import { Button } from '../shared/Button';
import { Typography } from '../shared/Typography';
import {
  desktopVW, mobileVW, color, minTablet, maxTablet,
} from '../styles';

export const OrderForm = ({
  totalDays, quantity, mainProduct, selectedDate, products, returnShipping,
} : any) => {
  const removeCartItems = useRemoveItemsFromCart();
  const addItemToCarts = useAddItemsToCart();
  const cart:any = useCart();
  const cartUrl = useCheckoutUrl();

  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    setSelectedProducts(products.map(({
      // eslint-disable-next-line no-shadow
      handle, title, quantity, variants,
    } : {handle: string, title: string, quantity: number, variants: any}) => ({
      handle, title, quantity, variant: variants.filter((obj : { title: string }) => Number(obj.title) === parseInt(totalDays, 10))[0],
    })));
  }, [products]);

  const mainProductObj: ({ variant: { price: 0 } }) = selectedProducts.filter(({ handle } : { handle : string }) => handle === mainProduct)[0] || ({ variant: { price: 0 } });
  const transmittorProductObj: ({ quantity: 2, variant: { price: 0 } }) = selectedProducts.filter(({ handle } : { handle: string }) => handle === 'zender')[0] || ({ quantity: 2, variant: { price: 0 } });

  const handleCart = (noRedirect? : any) => new Promise((resolve) => {
    if (!selectedProducts[0]) return;
    if (!cart.lineItems) return;

    //  Should be all rental products so it wont remove none rental products from the cart
    const rentalProducts = selectedProducts;
    const cartLineItems = cart.lineItems.filter((item : any) => [
      // TODO: get all rentalproducts to
      { title: 'Zender' },
      { title: 'Oplader koptelefoons' },
      { title: 'RGB koptelefoon' },
      { title: 'Polaroid-pakket' },
      { title: 'Rookmachine-pakket' },
      { title: 'Rookmachine pakket' },
      { title: 'Portable zender upgrade' },
      { title: 'Discolamp-pakket small' },
      { title: 'Discolamp-pakket medium : Laser + strobo + discolamp' },
      { title: 'Discolamp-pakket large: Rookmachine + Laser + strobo + discolamp' },
      { title: 'Polaroid pakket' }].some(({ title } : { title: string }) => title === item.title));

    const filterOutEmptyProducts = rentalProducts.filter((item: any) => item.quantity > 0);

    try {
      if (!cartLineItems.length) {
        addItemToCarts(filterOutEmptyProducts.map((item : any) => ({ customAttributes: [{ key: 'Lever datum', value: selectedDate[0].toLocaleDateString('en-US') }, { key: 'Inlever datum', value: selectedDate[1].toLocaleDateString('en-US') }, { key: 'Terug inleveren bij', value: returnShipping }], variantId: item.variant.storefrontId, quantity: parseInt(item.quantity, 10) })))
          .then(() => {
            resolve('resolve');
            if (noRedirect) return;
            window.location.href = cartUrl || '#';
          });
      } else {
        removeCartItems(cartLineItems.map((item : any) => item.variant.id))
          .then(() => {
            addItemToCarts(filterOutEmptyProducts.map((item : any) => ({ customAttributes: [{ key: 'Lever datum', value: selectedDate[0].toLocaleDateString('en-US') }, { key: 'Inlever datum', value: selectedDate[1].toLocaleDateString('en-US') }, { key: 'Terug inleveren bij', value: returnShipping }], variantId: item.variant.storefrontId, quantity: parseInt(item.quantity, 10) })))
              .then(() => {
                resolve('resolve');
                if (noRedirect) return;
                window.location.href = cartUrl || '#';
              });
          });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  });

  const textCheckout = [cart.lineItems && cart.lineItems.some((item : any) => item.title === 'RGB koptelefoon') ? 'Update je winkelwagen' : 'Voeg toe aan je mandje', 'Direct afrekenen'];

  return (
    <StyledOrderForm className="component">
      <Typography size="is-xxs" variants="h4">Jouw bestelling</Typography>
      <StyledTable>
        <tbody>
          <StyledRow>
            <td>Periode: van / tot</td>
            <td>
              {totalDays}
              {' '}
              dagen
            </td>
          </StyledRow>
          <StyledRow className="margin">
            <td>
              Aantal personen:
              {' '}
              {quantity}
            </td>
            <td>
              €
              {mainProductObj.variant ? (mainProductObj.variant.price * quantity).toFixed(2).toLocaleString() : 0 }
            </td>
          </StyledRow>
          <StyledRow>
            <td>Pakket korting</td>
            <td>€ 0</td>
          </StyledRow>
          <StyledRow className="margin">
            <td>
              Muziek kanalen:
              {' '}
              {transmittorProductObj.quantity}
            </td>
            <td>
              €
              {' '}
              {transmittorProductObj.variant ? transmittorProductObj.variant.price : 0 * transmittorProductObj.quantity}
            </td>
          </StyledRow>
          <StyledRow className="margin">
            <td>
              Extra’s:
            </td>
          </StyledRow>
          {selectedProducts.map(({
          // eslint-disable-next-line no-shadow
            handle, quantity, title, variant,
          } : any) => {
            if ([mainProduct, 'zender'].some((exclude) => exclude === handle)) return null;
            if (quantity) {
              return (
                <StyledRow>
                  <td>{title}</td>
                  <td>
                    €
                    {' '}
                    {(variant.price * quantity).toFixed(2).toLocaleString()}
                  </td>
                </StyledRow>
              );
            } return null;
          })}
        </tbody>
      </StyledTable>
      {textCheckout.map((item) => {
        const [isLoading, setIsLoading] = useState(false);
        return (
          <>
            <StyledButton
              handleClick={async () => {
                setIsLoading(true);
                await handleCart(!item.includes('Direct')).then(() => setIsLoading(false));
              }}
              type="button"
              size="is-sm"
            >
              {isLoading ? 'Loading...' : item }
            </StyledButton>
          </>
        );
      })}

    </StyledOrderForm>
  );
};

const StyledOrderForm = styled.div`
  padding: ${mobileVW(60)} ${mobileVW(15)};

  ${minTablet} {
    background: ${color.white};
    box-shadow: 0 ${desktopVW(3)} ${desktopVW(6)} #00000016;
    border-radius: ${desktopVW(10)};
    padding: ${desktopVW(60)} ${desktopVW(60)} ${desktopVW(60)} ${desktopVW(30)};
  }
`;

const StyledTable = styled.table`
  width: 100%;
  margin: ${mobileVW(30)} 0;

  ${minTablet} {
    margin: ${desktopVW(30)} 0;
  }
`;

const StyledRow = styled.tr`
  display: flex;
  justify-content: space-between;

  &.margin {
    margin-top: ${mobileVW(40)};

    ${minTablet} {
      margin-top: ${desktopVW(40)};
    }
  }
`;

const StyledButton = styled(Button)`
  display: block;
  cursor: pointer;

  ${maxTablet} {
    padding: ${mobileVW(17)};
  }

  & + & { 
    margin-top: ${desktopVW(15)}
  }
`;
